import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import /* Hero, */ { HeroInner } from "../components/hero"
import Image from "../components/Image"
import { pipesToSpan } from "../util/common"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
import FeaturesPackBlock from "../components/features-pack-6-block"
import TestimonialsSlideshow from "../components/testimonials-slideshow2"

function Solution({ data: { nodeSolution } }) {

  //console.log(nodeOverview)

  const { title, section2Title, relationships, showCTA, path,testimonialsTitle, showTestimonials } = nodeSolution
  const { hero, section1, section2 } = relationships
  const seo = getMetatags(nodeSolution.metatag, nodeSolution.relationships.OGImage?.localFile?.publicURL)

  let bgc = 'bg-grey1'
  switch (path.alias) {
    case '/revenue-leaders': bgc = 'bg-solution1'; break;
    case '/revenue-operations': bgc = 'bg-grey1'; break;
    case '/finance-team': bgc = 'bg-solution2'; break;
    case '/executive-team': bgc = 'bg-solution3'; break;
    default:
      break;
  }


  return (
    <Layout showCta={showCTA}>
      <Seo {...seo} />
      <HeroInner {...hero} tag={title} bgColor={bgc} />
      <Section1 items={section1} />
      {section2Title && <FeaturesPackBlock title={section2Title} items={section2} />}
      {showTestimonials && <TestimonialsSlideshow title={testimonialsTitle} isHomepage={false} />}
    </Layout>
  )
}


export default Solution

const Section1 = ({ items, ...props }) => {

  return (
    <section>
      <div className="hidden lg:block">
        {items.map((item, i) => (
          <Section1Card {...item} key={i} id={i} />
        ))}
      </div>
      <div className="lg:hidden">
        {items.map((item, i) => (
          <Section1CardMobile {...item} key={i} id={i} />
        ))}
      </div>
    </section>
  )
}

const Section1Card = ({ id, title, description, image, relationships, ...props }) => {

  const titleHtml = pipesToSpan(title)
  const odd = id % 2 === 0
  //console.log(image)
  /*
    const pic = (
      <div className="lg:w-1/2 pb-8 pt-4 lg:pb-0 lg:pt-0 border ">
        <Image image={relationships.image} alt={image.alt} className="max-w-[500px] border  mr-auto block" />
      </div>
    ) */

  const text = (
    <div className="lg:w-1/2 ">
      <div className="lg:px-8">
        <h3 className="text-3xl lg:text-4xl font-bold -max-w-[500px]" dangerouslySetInnerHTML={{ __html: titleHtml }} />
        <p className="text-lg lg:text-xl lg:leading-8	 font-light pt-6 lg:py-6 max-w-[500px] solutions-description-bottom" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  )
  return (
    <div className={"max-w-[1200px] mx-auto flex flex-col lg:flex-row py-4 lg:py-12 "} >

      {odd && text}
      {odd && <Pic image={image} relationships={relationships} alignTo="right" />}

      {!odd && <Pic image={image} relationships={relationships} alignTo="left" />}
      {!odd && text}

    </div>
  )
}

const Pic = ({ image, relationships, alignTo }) => {

  const c = alignTo === "left" ? ' mr-auto' : ' ml-auto'
  //console.log(image)
  return (
    <div className="lg:w-1/2 pb-8 pt-4 lg:pb-0 lg:pt-0 ">
      <Image image={relationships.image} alt={image?.alt} className={"max-w-[500px] block " + c} width={image?.width} height={image?.height} />
    </div>
  )
}


const Section1CardMobile = ({ id, title, description, image, relationships, ...props }) => {

  const titleHtml = pipesToSpan(title)
  //console.log(image)
  return (
    <div className={"px-4 py-4 lg:py-24"} >
      <div className="lg:w-1/2 ">
        <div className="lg:p-8">
          <h3 className="text-3xl lg:text-4xl font-bold -max-w-[500px]" dangerouslySetInnerHTML={{ __html: titleHtml }} />
          <p className="text-lg lg:text-xl lg:leading-8	 font-light pt-6 lg:py-6 max-w-[500px] solutions-description-bottom" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
      <Pic image={image} relationships={relationships} alignTo="right" width={image?.width} height={image?.height} />

    </div>
  )
}



export const query = graphql`
  query solution($id: String!) {
    nodeSolution(id: { eq: $id }) {
      id
      title
      section2Title : field_title
      showCTA: field_show_book_demo
      testimonialsTitle : field_title3
      showTestimonials: field_show_testimonials
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path{
        alias
      }
      relationships {
        hero: field_hero {
          ...HeroContent
        }
        section1: field_basic_box {
          ...ParagraphGenericBlock
        }
        section2: field_items {
          ...ParagraphImageAndText
        }
      }
    }
  }
`
